import { InView } from "react-intersection-observer"
/** @jsx jsx */
import { css, jsx } from "@emotion/core"

const Section = ({ title, reff, onAppear, maxWidth, children, ...props }) => {
  return (
    <section ref={reff}>
      <InView rootMargin="0% 0% 0% 0%" threshold={0.5}>
        {({ inView, ref, entry }) => {
          onAppear && onAppear(inView)
          return (
            <div
              ref={ref}
              css={css`
                margin-bottom: 100vh;
              `}
              {...props}
            >
              <h2
                css={theme => css`
                  position: sticky;
                  z-index: 99;
                  top: ${theme.pagePadding[0]};
                  padding: 0.4em 0.4em 0.4em 0;
                  text-transform: uppercase;
                  display: inline-block;
                  background: ${theme.color.bg};
                  color: ${theme.color.fg};
                  margin: 0;
                `}
              >
                {title}
              </h2>
              <div
                css={css`
                  max-width: ${maxWidth || "40em"};
                  margin: 0 0 0 auto;
                `}
              >
                {children}
              </div>
            </div>
          )
        }}
      </InView>
    </section>
  )
}

export default Section
