import { Fragment } from "react"
/** @jsx jsx */
import { css, jsx } from "@emotion/core"

import BaseBlock from "./BaseBlock"

const ImageBlock = ({ pic, alt, odd, caption, children, ...props }) => {
  return (
    <BaseBlock
      css={css`
        max-width: 40em;
        margin: 1em ${odd ? "auto" : "1em"} 1em ${!odd ? "auto" : "1em"};
      `}
      {...props}
    >
      {({ inView }) => (
        <Fragment>
          <div
            css={css`
              width: 100%;
              position: relative;
              height: 0;
              padding-bottom: ${(1 / pic.aspectRatio) * 100}%;
            `}
          >
            <img
              css={css`
                width: 100%;
                height: 100%;
                display: block;
                position: absolute;
                box-shadow: 0 0 0.2em black;
              `}
              src={inView ? pic.src : pic.base64}
              alt={alt}
            />
          </div>
          {caption && (
            <p
              css={theme => css`
                display: inline;
                background: ${theme.color.bg};
                font-size: 87%;
              `}
            >
              {caption}
            </p>
          )}
          {children}
        </Fragment>
      )}
    </BaseBlock>
  )
}

export default ImageBlock
