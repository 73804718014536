/** @jsx jsx */
import { css, jsx } from "@emotion/core"

import BaseBlock from "./BaseBlock"

const TextBlock = ({ tag, odd, children, ...props }) => {
  const Tag = tag || "div"
  return (
    <BaseBlock
      css={css`
        max-width: 40em;
        margin: 1em ${odd ? "auto" : "1em"} 1em ${!odd ? "auto" : "1em"};
      `}
    >
      <Tag
        css={theme => css`
          margin: 0;
          padding: 0;
          background: ${theme.color.bg};
          padding: 1em;
        `}
        {...props}
      >
        {children}
      </Tag>
    </BaseBlock>
  )
}

export default TextBlock
