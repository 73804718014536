import { Fragment, useEffect, useCallback, useState } from "react"
/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { motion, AnimatePresence } from "framer-motion"

import BaseBlock from "./BaseBlock"

const Link = ({ label, href }) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      css={theme => css`
        flex: 1 1 auto;
        display: block;
        padding: 1em;
        background: ${theme.color.bg};
        text-align: center;
        color: inherit;
        font-weight: bold;

        &:hover {
          text-decoration: none;
          background: ${theme.color.fg};
          color: ${theme.color.bg};
        }
      `}
    >
      {label}
    </a>
  )
}

const PackShot = ({ images }) => {
  const [pos, setPos] = useState(0)

  const onStep = useCallback(() => {
    setPos((pos + 1) % images.length)
  }, [pos, images.length])

  useEffect(() => {
    const interval = setInterval(onStep, 2800)
    return () => clearInterval(interval)
  }, [onStep])

  return (
    <BaseBlock
      css={css`
        max-width: 40em;
        margin: 1em auto;
      `}
    >
      {({ inView }) => (
        <Fragment>
          <div
            css={css`
              width: 100%;
              position: relative;
              height: 0;
              padding-bottom: ${(1 / images[pos].aspectRatio) * 100}%;
            `}
          >
            <AnimatePresence>
              <motion.img
                key={pos}
                initial={{ rotateY: "-90deg", opacity: 0 }}
                animate={{ rotateY: "0deg", opacity: 1 }}
                exit={{ rotateY: "90deg", opacity: 0 }}
                css={css`
                  width: 100%;
                  height: 100%;
                  display: block;
                  position: absolute;
                  box-shadow: 0 0 0.2em black;
                `}
                src={inView ? images[pos].src : images[pos].base64}
                alt="EXO Cover"
              />
            </AnimatePresence>
          </div>
          <p
            css={theme => css`
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              background: ${theme.color.bg};
            `}
          >
            <Link
              label="Buy on Bandcamp"
              href="https://bit-tuner.bandcamp.com/album/exo"
            />
            <Link
              label="Listen on Spotify"
              href="https://open.spotify.com/album/3iVLAEuuDZOeOtCWoWHCzS?si=3z3IyggxSu2LGhc4Syn9vg"
            />
            <Link
              label="Listen on Apple Music"
              href="https://music.apple.com/album/exo/1494509922"
            />
          </p>
        </Fragment>
      )}
    </BaseBlock>
  )
}

export default PackShot
