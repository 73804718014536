/** @jsx jsx */
import { jsx } from "@emotion/core"
import { InView } from "react-intersection-observer"
import { motion } from "framer-motion"

const variants = {
  visible: { opacity: 1, y: 0 },
  above: { opacity: 0, y: "-100%" },
  below: { opacity: 0, y: "100%" },
  hidden: { opacity: 0, y: 0 },
}

const BaseBlock = ({ children, ...props }) => {
  return (
    <InView rootMargin="0% 0% -30% 0%" threshold={0.1}>
      {({ inView, ref, entry }) => {
        const isAbove = entry
          ? entry.boundingClientRect.top <= entry.rootBounds.top
          : undefined
        return (
          <div ref={ref} {...props}>
            <motion.div
              variants={variants}
              animate={
                inView
                  ? "visible"
                  : isAbove === undefined
                  ? "hidden"
                  : isAbove
                  ? "above"
                  : "below"
              }
            >
              {typeof children === "function"
                ? children({ inView: inView })
                : children}
            </motion.div>
          </div>
        )
      }}
    </InView>
  )
}

export default BaseBlock
