/** @jsx jsx */
import { css, jsx } from "@emotion/core"

import BaseBlock from "./BaseBlock"

const EventBlock = ({ date, location, title, odd, children, ...props }) => {
  return (
    <BaseBlock
      css={css`
        max-width: 22em;
        margin: 1em ${odd ? "auto" : "0"} 1em ${!odd ? "auto" : "0"};
      `}
      {...props}
    >
      <div
        css={theme => css`
          background: ${theme.color.bg};
          padding: 1em;
        `}
      >
        <h2
          css={css`
            padding: 0;
            margin: 0;
            font-size: 120%;
          `}
        >
          {date}, {location}
        </h2>
        <p
          css={css`
            padding: 0;
            margin: 1em 0 0 0;
          `}
        >
          {title}
        </p>
      </div>
    </BaseBlock>
  )
}

export default EventBlock
