/** @jsx jsx */
import { css, jsx } from "@emotion/core"

import BaseBlock from "./BaseBlock"

const ReviewBlock = ({ quote, author, href, isGerman, odd, ...props }) => {
  return (
    <BaseBlock
      css={css`
        max-width: 21em;
        margin: 1em ${odd ? "auto" : "0"} 1em ${!odd ? "auto" : "0"};
      `}
      {...props}
    >
      <div
        css={theme => css`
          background: ${theme.color.bg};
          padding: 1em;
        `}
      >
        <blockquote
          css={css`
            padding: 0;
            margin: 0 0 0 1em;
            font-size: 120%;
          `}
        >
          {quote}
        </blockquote>
        <p
          css={css`
            padding: 0;
            margin: 1em 0 0 auto;
          `}
        >
          {href ? (
            <a
              href={href}
              target="_blank"
              rel="noopener noreferrer"
              css={css`
                color: inherit;
              `}
            >
              {author}
            </a>
          ) : (
            author
          )}
          {isGerman && (
            <span
              css={css`
                font-size: 80%;
                text-decoration: none;
              `}
            >
              {" "}
              (German)
            </span>
          )}
        </p>
      </div>
    </BaseBlock>
  )
}

export default ReviewBlock
