import React from "react"
import { useStaticQuery, graphql } from "gatsby"
/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import Layout from "../components/layout"
import SvgContainer from "../components/SvgContainer"
import SEO from "../components/seo"
import TextBlock from "../components/TextBlock"
import EventBlock from "../components/EventBlock"
import ReviewBlock from "../components/ReviewBlock"
import ImageBlock from "../components/ImageBlock"
import PackShot from "../components/PackShot"
import Support from "../components/Support"
import Section from "../components/Section"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      coverImage: file(relativePath: { eq: "exo-cover.png" }) {
        childImageSharp {
          pic: fluid(base64Width: 48, maxWidth: 960) {
            base64
            src
            srcWebp
            aspectRatio
          }
        }
      }
      liveImage1: file(
        relativePath: { eq: "Bit-Tuner-EXO-live1Pusterla.jpg" }
      ) {
        childImageSharp {
          pic: fluid(base64Width: 48, maxWidth: 1200) {
            base64
            src
            srcWebp
            aspectRatio
          }
        }
      }
      liveImage2: file(
        relativePath: { eq: "Bit-Tuner-EXO-live2Pusterla.jpg" }
      ) {
        childImageSharp {
          pic: fluid(base64Width: 48, maxWidth: 1200) {
            base64
            src
            srcWebp
            aspectRatio
          }
        }
      }
      packShot1: file(
        relativePath: { eq: "OUS027---Bit-Tuner--Packshot-003.jpg" }
      ) {
        childImageSharp {
          pic: fluid(base64Width: 48, maxWidth: 1200) {
            base64
            src
            srcWebp
            aspectRatio
          }
        }
      }
      packShot2: file(
        relativePath: { eq: "OUS027---Bit-Tuner--Packshot-004.jpg" }
      ) {
        childImageSharp {
          pic: fluid(base64Width: 48, maxWidth: 1200) {
            base64
            src
            srcWebp
            aspectRatio
          }
        }
      }
      packShot3: file(
        relativePath: { eq: "OUS027---Bit-Tuner--Packshot-006.jpg" }
      ) {
        childImageSharp {
          pic: fluid(base64Width: 48, maxWidth: 1200) {
            base64
            src
            srcWebp
            aspectRatio
          }
        }
      }
    }
  `)

  const refReviews = React.useRef()
  const refEvents = React.useRef()
  const refAbout = React.useRef()
  const refOrder = React.useRef()
  const refSupport = React.useRef()

  const [visibleSection, setVisibleSection] = React.useState(null)

  return (
    <Layout
      nav={[
        { title: "About", ref: refAbout, visible: visibleSection === "about" },
        {
          title: "Reviews",
          ref: refReviews,
          visible: visibleSection === "reviews",
        },
        {
          title: "Events",
          ref: refEvents,
          visible: visibleSection === "events",
        },
        { title: "Order", ref: refOrder, visible: visibleSection === "order" },
      ]}
      headerTitle={
        <>
          <div
            css={css`
              width: 13vw;
              min-width: 10em;
            `}
          >
            <SvgContainer width={111.08} height={16.392} title="Bit-Tuner">
              <path
                fill="#231F20"
                d="M11.53 11.266c0 3.036-2.2 4.797-5.897 4.797H0V0h5.325c3.499 0 4.929 2.178 4.929 4.137 0 1.584-.858 2.618-1.65 3.124 1.495.44 2.926 1.65 2.926 4.005zm-6.293-9.33h-3.08v4.709h3.256c1.804 0 2.684-.946 2.684-2.398 0-1.189-.792-2.311-2.86-2.311zm4.136 9.286c0-1.76-1.078-2.706-3.521-2.706H2.156v5.611h3.432c2.861-.001 3.785-1.167 3.785-2.905zM16.896 0v16.062H14.74V0h2.156zm14.39 0v1.936h-4.753v14.126h-2.156V1.936h-4.841V0h11.75zm6.313 8.845h-5.985V7.041h5.985v1.804zM49.678 0v1.936h-4.753v14.126h-2.156V1.936h-4.841V0h11.75zm2.638 0h2.156v10.077c0 2.904 1.54 4.445 4.071 4.445 2.552 0 4.071-1.54 4.071-4.445V0h2.156v10.231c0 3.851-2.376 6.161-6.227 6.161-3.829 0-6.227-2.31-6.227-6.161V0zM82.26 0v16.062h-1.65L70.862 3.895h-.022v12.168h-2.156V0H70.4l9.681 12.08h.022V0h2.157zm13.441 14.126v1.936h-9.527V0h9.395v1.936H88.33v4.665h6.469v1.936H88.33v5.589h7.371zm15.379 1.914v.022h-2.596l-5.545-6.843h-1.76v6.843h-2.156V0h5.259c3.212 0 5.017 2.244 5.017 4.621 0 2.398-1.54 4.027-3.873 4.511l5.654 6.908zm-9.902-8.691h2.926c1.87 0 3.036-1.166 3.036-2.706 0-1.562-1.122-2.706-2.992-2.706h-2.97v5.412z"
              />
            </SvgContainer>
          </div>
          <h2
            css={css`
              font-size: 100%;
            `}
          >
            Presents: EXO — Live A/V Show
            <br />A dimensional shift.
          </h2>
        </>
      }
    >
      <SEO title="EXO" />

      <Section
        onAppear={inView => {
          setVisibleSection(
            !inView
              ? visibleSection === "about"
                ? null
                : visibleSection
              : "about"
          )
        }}
        reff={refAbout}
        title="About"
        maxWidth="none"
      >
        <TextBlock tag="h3" odd>
          Bit-Tuner presents his new album EXO accompanied by a visual narration
          by filmmaker Joerg Hurschler.
        </TextBlock>
        <TextBlock tag="p">
          Bit-Tuner’s new album «EXO» marks a milestone in his work. Focussing
          on musical storytelling and atmospheric depth, he is releasing a
          widescreen and beatless opus that invites listeners to dive deep into
          his sonic worlds. To a great extent, the album was written and
          recorded towards the end of his 2-year stay in Athens.
        </TextBlock>
        <ImageBlock
          pic={data.liveImage1.childImageSharp.pic}
          alt="At work"
          caption="Live @ Kunstraum Walcheturm / Photo: Lorenzo Pusterla"
          odd
        ></ImageBlock>
        <TextBlock tag="p">
          Influenced by topics like the social and structural turmoils of the
          past years, the strong connections between communities and the
          sensation of being in an economic deadlock, Bit-Tuner wrote an album
          that urges to be listened to in a (self-)reflective way. It is a call
          to listeners to listen closely, delve into the sounds surrounding them
          in the very moment and draw a quiet but firm inspiration from within.
        </TextBlock>
        <ImageBlock
          pic={data.liveImage2.childImageSharp.pic}
          alt="At work"
          caption="Live @ Kunstraum Walcheturm / Photo: Lorenzo Pusterla"
          odd
        ></ImageBlock>
        <TextBlock tag="p">
          Filmmaker Joerg Hurschler takes the album a step further by creating a
          40-minute animated video that will be screened, mixed and live scored
          exclusively during Bit-Tuner’s concerts in 2020. The film follows the
          dramaturgy of the album, creating a visual narration based on its
          atmosphere. It is the story of molecular objects being propelled into
          a world similar to ours, where they operate, interact and affect their
          surroundings, creating and leaving behind something new and strange.
          Joerg Hurschler’s carefully crafted 3D animations add another layer:
          What is it that surrounds us, and how do we approach and interact with
          forces that are beyond our (apparent) reach?
          <br />
          The combination of Joerg Hurschler’s visual storywold and Bit-Tuner’s
          atmospheric music congregate into an audiovisual experience between
          the disciplines.
        </TextBlock>
      </Section>

      <Section
        onAppear={inView => {
          setVisibleSection(
            !inView
              ? visibleSection === "reviews"
                ? null
                : visibleSection
              : "reviews"
          )
        }}
        reff={refReviews}
        title="Reviews"
        css={css`
          margin-top: 100vh;
        `}
      >
        <ReviewBlock
          quote="«There’s a physicality to EXO, however subtle and subliminal: there are textures that make your skin crawl, tonalities that make you twitch, tense, and tingle.»"
          author="Aural Aggravation"
          href="https://auralaggravation.com/2020/01/08/bit-tuner-exo/"
        />
        <ReviewBlock
          quote="«Bit-Tuner accomplishes mastery in arousing emotions of trepidation and suspense.»"
          author="Wül Magazine"
          href="https://www.wulmagazine.com/art/bit-tuner"
          odd
        />
        <ReviewBlock
          quote="«Atmospheric, obscure, hopeful.»"
          author="Saiten Magazin"
          href="https://www.saiten.ch/atmosphaerisch-duester-hoffnungsvoll/"
          isGerman
        />
        <ReviewBlock
          quote="«Bit-Tuner presents an inward looking, reflective melancholia contrasted with majestic, heavenly layered tracks.»"
          author="Nitestylez"
          href="http://www.nitestylez.de/2020/01/bit-tuner-exo-ous-027-promo.html"
          odd
        />
        <ReviewBlock
          quote="«Bit-Tuner's 7th album EXO is an opulent observation of exotic worlds.»"
          author="Artnoir"
          href="https://artnoir.ch/bit-tuner-exo/"
          isGerman
        />
        <ReviewBlock
          quote="«EXO is a blackening virus, a virtual painting, a cheeping landscape. And: EXO tells a story.»"
          author="St.Galler Tagblatt"
          href="https://www.tagblatt.ch/kultur/bit-tuner-verzichtet-auf-beats-der-stgaller-musiker-ueberrascht-mit-seinem-siebten-album-exo-ld.1175638"
          isGerman
          odd
        />
        <ReviewBlock
          quote="«Sweepingly widescreen but intimately detailed electronic spaces.»"
          author="Boomkat"
          href="https://boomkat.com/products/exo-c2dd5e79-3893-4e14-bbf9-30f0c64490fa"
        />
        <ReviewBlock
          quote="«Bit-Tuner’s 7th album is an introspective and reflective one, [...] filtering that mood into a pure strain of gentle melancholy that is then expressed in expansive, filmic instrumental electronica.»"
          author="Chain D.L.K."
          href="http://www.chaindlk.com/reviews/?id=11211"
          odd
        />
      </Section>

      <Section
        onAppear={inView => {
          setVisibleSection(
            !inView
              ? visibleSection === "events"
                ? null
                : visibleSection
              : "events"
          )
        }}
        reff={refEvents}
        title="Events"
      >
        <EventBlock
          title="Bit-Tuner presents «EXO» + LIVE: BIT-TUNER CLUB-SET /// DJ's: ZUNAMI
      & INK! (-OUS)"
          date="Sat 8 Feb 2020"
          location="Kino Cameo & Kraftfeld, Winterthur"
        />
        <EventBlock
          title="Bit-Tuner presents «EXO» (live A/V show) / MM (live) /// -OUS Label
      Night"
          date="Thu 13 Feb 2020"
          location="Dampfzentrale, Bern"
          odd
        />
        <EventBlock
          title="Bit-Tuner presents «EXO» + Club-Set /// DJ's Shayu und Ink!"
          date="Fri 14 Feb 2020"
          location="Royal, Baden"
        />
        <EventBlock
          title="Bit-Tuner presents «EXO» (live A/V show) /// HTW Herrrouine & Vinc."
          date="Sat 29 Feb 2020"
          location="Rouine, Basel"
          odd
        />
      </Section>

      <Section
        onAppear={inView => {
          setVisibleSection(
            !inView
              ? visibleSection === "order"
                ? null
                : visibleSection
              : "order"
          )
        }}
        reff={refOrder}
        title="Order"
      >
        <PackShot
          images={[
            data.packShot1.childImageSharp.pic,
            data.packShot2.childImageSharp.pic,
            data.packShot3.childImageSharp.pic,
          ]}
        />
      </Section>

      <Section
        onAppear={inView => {
          setVisibleSection(
            !inView
              ? visibleSection === "support"
                ? null
                : visibleSection
              : "support"
          )
        }}
        reff={refSupport}
        title="Supported by"
      >
        <Support />
      </Section>
    </Layout>
  )
}

export default IndexPage
